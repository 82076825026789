import React from 'react';

import {Link} from 'gatsby';

import ContentTypeTag from './content-type-tag';

import styles from './page-card.module.css';

const PageCard = ({page}) => (
  <div className={styles.card}>
    <div className={styles.titleRow}>
      <Link to={page.path} className={styles.link}>{page.title}</Link>
      <ContentTypeTag type={page.contentType} />
    </div>
    <div className={styles.summary}>{page.summary}</div>
  </div>
);

export default PageCard;