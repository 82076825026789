import React from 'react';

import minimatch from 'minimatch';
import {find} from 'lodash';

import CardList from './card-list';

import {URLConsumer} from './url-container';

const generateRecommendations = (url, pages) => {
  // If there isn't a URL, then we can't recommend anything
  if (!url) {
    return [];
  }

  const path = new URL(url).pathname;

  return pages.filter(page => {
    return find(page.relevantTo, r => minimatch(path, r));
  })
};

const RecommendedCardList = ({pages}) => (
  <URLConsumer>{({url}) => {
    const recommendations = generateRecommendations(url, pages);

    if (recommendations.length === 0) return null;

    return (
      <CardList header="Recommended" pages={recommendations} />
    );
  }}</URLConsumer>
);

export default RecommendedCardList;