import React from 'react';

import PageCard from './page-card';

import styles from './card-list.module.css';

const CardList = ({pages, header}) => {
  // Don't render if we have no items
  if (pages.length === 0) return null;

  return (
    <div className={styles.list}>
      {header ? <h2 className={styles.header}>{header}</h2> : null}
      {pages.map(page => <PageCard key={page.path} page={page} />)}
    </div>
  );
};

export default CardList;