import React from 'react';

import CardList from './card-list';

const PopularCardList = ({pages}) => {
  const popular = pages.filter(page => page.popular);

  return (
    <CardList header="Popular" pages={popular} />
  );
};

export default PopularCardList;