import React from 'react';

const Names = {
  explanations: 'Explanation',
  "how-to": 'How To'
}
const Colours = {
  explanations: "#3C93F7",
  "how-to": "#499665"
}

const ContentTypeTag = ({type}) => {
  const name = Names[type] || type;
  
  return <span style={{backgroundColor: Colours[type], 'padding': '4px', color: 'white', fontSize: '0.6em', verticalAlign: 'middle'}}>{name}</span>;
};

export default ContentTypeTag;