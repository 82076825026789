import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import * as JsSearch from "js-search"
import CardList from '../components/card-list';
import {CategoriesConsumer} from '../components/url-container';
import RecommendedCardList from '../components/recommended-card-list';
import PopularCardList from '../components/popular-card-list';

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Home" />

    <h1>Training Centre</h1>

    <CategoriesConsumer>{({categories}) => (
      <SearchableCardList pages={data.siteSearchIndex.index} categories={categories} />
    )}</CategoriesConsumer>
  </Layout>
)

class SearchBox extends React.Component {
  render() {
    return (
      <form onSubmit={this._preventSubmit}>
        <div style={{ margin: "0 auto" }}>
          <input
            id="Search"
            value={this.props.searchQuery}
            onChange={this.props.onChange}
            placeholder="Search"
            style={{ margin: "0 auto", width: "100%" }}
          />
        </div>
      </form>
    );
  }

  _preventSubmit = e => {
    e.preventDefault()
  }
}

class SearchableCardList extends React.Component {
  constructor(props) {
    super(props);

    this.availablePages = this.props.pages.filter(page => {
      if (page.availableCategories) {
        // Return false if we can't find an available category that has an entry included in our categories list
        return !!page.availableCategories.find(category => this.props.categories.find(c => c === category));
      } else {
        return true;
      }
    });

    this.search = new JsSearch.Search("title");

    this.search.indexStrategy = new JsSearch.PrefixIndexStrategy()
    this.search.sanitizer = new JsSearch.LowerCaseSanitizer()
    this.search.searchIndex = new JsSearch.TfIdfSearchIndex("title")
    this.search.addIndex("title");
    this.search.addIndex("summary");
    this.search.addDocuments(this.availablePages);

    this.state = {
      searchResults: [],
      searchQuery: "",
    };
  }
  
  _search = e => {
    if (e.target.value === '') {
      this.setState({searchQuery: '', searchResults: []});
    } else {
      const queryResult = this.search.search(e.target.value);

      this.setState({ searchQuery: e.target.value, searchResults: queryResult });    
    }
  }

  render() {
    const {searchResults, searchQuery} = this.state,
          isSearchResults = searchQuery !== '';

    return (
      <div>
        <div style={{ margin: "0 auto" }}>
          <SearchBox searchQuery={searchQuery} onChange={this._search} />
          {
            isSearchResults ? (
              <CardList pages={searchResults} />
            ) : (
              <>
                <RecommendedCardList pages={this.availablePages} />
                <PopularCardList pages={this.availablePages} />
              </>
            )
          }
        </div>
      </div>
    )
  }
}

export const query = graphql`
  query {
    siteSearchIndex {
      index
    }
  }
`;

export default IndexPage
